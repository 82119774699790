import React, { useState } from 'react'
import App from 'App'
import { COLOR_CONSTANTS } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Footer from 'components/Footer'
import Modal from 'components/Modal'
import { Widget } from '@typeform/embed-react'
import Item from './Item'

const MOCK_CAREERS = [
  // {
  //   id: 21,
  //   type: 'Anywhere · Full time · Marketing',
  //   position: 'Marketing Lead, Extraordinaire',
  //   link: '#',
  //   description: `We are looking for an experienced and highly motivated marketing leader to join our team to help propel our current marketing efforts! Ideal individual loves marketing, understands and enjoys working in a highly agile and collaborative manner, and is a joy to be around for colleagues and clients.`,
  // },
  // {
  //   id: 11,
  //   type: 'Anywhere · Full time · Marketing',
  //   position: 'Videographer, Content Visionary',
  //   link: '#',
  //   description:
  //     "As a Videographer, you'll join our marketing team to create engaging video content for our YouTube channel, website, and social media. We seek passionate visual storytellers with a keen understanding of YouTube algorithms. Experience in shooting, editing, and optimizing video content for viewer retention is a big plus.",
  // },
  {
    id: 12,
    type: 'Anywhere · Full time · Marketing',
    position: 'Content Marketer, Expert Storyteller',
    link: '#',
    description: `As a Content Marketer you will join our marketing team and be responsible for creating content for our blog, web, email marketing camapigns, ads, and social media. We are looking for tallented and passionate story tellers and expert writers. Understanding of best SEO practices is a big plus.`,
  },

  {
    id: 11,
    type: 'Anywhere · Full time · Customer Success',
    position: 'Customer Advocate',
    link: '#',
    description:
      "We're passionate about guiding customers to success using Vista Social products as well as helping them hit their social media goals. We’re looking for new members to join our Customer Advocacy team and help our customers get the most out of the entire Vista Social platform. ",
  },

  // {
  //   id: 13,
  //   type: 'Anywhere · Full time',
  //   position: 'Product Marketing Manager',
  //   link: '#',
  //   description: `As a Product Marketing Manager you'll own the roadmap for Vista Social product launches and help shape the go-to-market strategy. You'll work closely with cross-functional teams at Vista Social to align marketing efforts with our strategic vision, define success criteria, and develop clear plans and milestones to maximize Vista Social's adoption by the small business market. `,
  // },
  {
    id: 14,
    type: 'Anywhere · Full time · Engineering',
    position: 'Engineering Manager',
    link: '#',
    description: `We’re looking for an engineering manager for our product engineering team. You’ll be supporting a team of engineers to build our IOS and Android Mobile apps. You’ll initially be managing a single team, but this scope may expand to include a second smaller team.
    `,
  },
  {
    id: 9,
    type: 'Anywhere · Full time · Engineering',
    position: 'Front End Developer',
    link: '#',
    description: `We are looking for brilliant front end developer to help us take our platform to the next level. You will be solving UI/UX challanges by making complex features user friendly and easy to understand. You will work side by side with enthusiastic, skillful and joy to be around team!`,
  },

  {
    id: 15,
    type: 'Anywhere · Full time · Engineering',
    position: 'QA Analyst, Web & Mobile',
    link: '#',
    description: `We are looking for a QA Analyst who is a critical thinker and a powerful advocate for our users. Become an expert on the features and functionalities of Vista Social. Perform hands-on testing across platforms. Work with teams to help evolve software delivery processes.`,
  },
]

const Careers = () => {
  const [isOpen, setIsOpen] = useState(false)

  const apply = () => {
    setIsOpen(true)
  }

  const handleDismiss = () => {
    setIsOpen(false)
  }
  return (
    <App>
      <SEO
        title="Careers"
        description="Working at Vista Social is more than just a job. Want to come along for the ride?"
        path="/careers/"
      />
      <Modal isOpen={isOpen} handleDismiss={handleDismiss} maxwidth="880px" translatetop="20">
        <Widget id="lhZ7tNlD" style={{ width: '100%', height: '550px' }} />
      </Modal>

      <Box pl={{ mobile: 'l', tablet: 'l' }} pr={{ mobile: 'l', tablet: 'l' }}>
        <Flex mt="xl" alignItems="center" flexDirection="column">
          <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold" textAlign="center">
            Join the{' '}
            <H1 fontSize="4xl" fontWeight="bold" color="primary" as="span">
              Vista Social{' '}
            </H1>
            Journey
          </H1>
          <Text mt="m" fontSize="l" color="secondaryText" textAlign="center">
            Working at Vista Social is more than just a job. Want to come along for the ride?
          </Text>
        </Flex>
        <Flex justifyContent="center">
          <Flex mt="xl" mb="xl" maxWidth="1200px">
            <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
              {MOCK_CAREERS.map((item) => (
                <Item key={item.id} {...item} onClick={apply} />
              ))}
            </Grid>
          </Flex>
        </Flex>

        <Footer />
      </Box>
    </App>
  )
}

export default Careers
